import { useEffect } from 'react';
import { useTranslation } from '../i18n/i18n';

export const About = () => {
  const { t } = useTranslation();
  const aboutUs2Customized = () => {
    const text = t('common.aboutUs2');
    const index = text.indexOf('Consultor Logístico');
    
    if (index !== -1) {
      const boldText = text.substring(
        index,
        index + 'Consultor Logístico'.length
      );
      const newText = text.replace(boldText, `<strong>${boldText}</strong>`);
      return newText;
    }

    return text;
  };
  useEffect(() => {
    aboutUs2Customized();
  });
  return (
    <div
      id="about"
      style={{
        backgroundColor: '#FFFFFF!important',
        textAlign: 'justify',
        textJustify: 'inter-word',
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {' '}
            <img
              style={{ boxShadow: 'none' }}
              src="img/about.png"
              className="img-responsive"
              alt=""
            />{' '}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{t('common.aboutUs')}</h2>
              <p>{t('common.aboutUs1')}</p>
              <p dangerouslySetInnerHTML={{ __html: aboutUs2Customized() }}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
