import { useTranslation } from '../i18n/i18n';
import './assets/header.css';

export const Header = (props) => {
  const { t } = useTranslation();

  return (
    <header id="home">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className="principal-title">
                  {t('common.slogan')}
                  <span></span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
