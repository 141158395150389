import { useState, useRef } from 'react';
import { useTranslation } from '../i18n/i18n';
import './assets/contact.css';
const initialState = {
  name: '',
  email: '',
  message: '',
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    fetch('https://send.pageclip.co/Lr437mIUOZsEylKQtOhC87zhbOZgFPJZ', {
      method: 'POST',
      body: JSON.stringify({
        name: name,
        email: email,
        message: message,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (formRef.current) {
          formRef.current.reset();
        }
        clearState();
        setSuccessSubmit(true);
        setIsSubmitting(false);
      });  
  };

  const currentYear = new Date().getFullYear();
  
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-sm-12">
            <div className="row">
              <div className="section-title">
                <h2>{t('common.getInTouch')}</h2>
                <p>{t('common.getInTouchDescription')}</p>
              </div>
              <form
                ref={formRef}
                name="sentMessage"
                validate="true"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={t('common.name')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={t('common.email')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={t('common.message')}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <span
                      className="fa fa-spinner fa-spin-pulse"
                      style={{ marginRight: 10 }}
                    ></span>
                  )}
                  {t('action.sendMessage')}
                </button>
              </form>
              {successSubmit && (
                <div className="alert alert-success" role="alert">
                  {t('common.thanksForContactingUs')}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 contact-info">
            <div className="contact-item">
              <div>
                <span>
                  <i className="fa fa-map-marker"></i> {t('common.address')}
                </span>
                <strong>{t('common.dominicanRepublic')}</strong>
                <p>
                  Av. Tiradentes Esq. Presidente Gonzalez Edif. La isla, Piso 4
                  Ens. Naco, Sto. Dgo. <br /> República Dominicana <br /> +1 809
                  518 4218
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 contact-info">
            <div className="contact-item">
              <div>
                <span>
                  <i className="fa fa-map-marker"></i> {t('common.address')}
                </span>
                <strong>{t('common.panama')}</strong>
                <p>
                  v. Aquilino de la Guardia, Ocean Business Plaza, Piso 12,
                  Oficina 1203 <br /> Panamá
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; {currentYear} CTL Cargo </p>
        </div>
      </div>
    </div>
  );
};
