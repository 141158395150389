// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    common: {
      companyName: 'CTL Cargo',
      slogan: "Your global logistics solution",
      aboutUs1: "CTL is a young but experience logistics company, focused on initiative and customer experience. We understand that each and everyone of our clients have its own requirements and our goal is that by the end of the day, we make our client’s logistics process easier and more efficient.",
      aboutUs2: "As a Freight Forwarder, our focus is being your partner, an extension from each one of our clients. We can offer all transportation options from every part of the world as a <strong>Logistics Consultant</strong>, for that we rely on our strong networks capabilities, which gives us coverage all around the world.",
      whatCanWeDoForYou: "What can we do for YOU?",
      whatCanWeDoForYouDescription: "As a Freight Forwarder, our focus is being you partner, an extension from each one of our clients. To do so, we have joined with WWPC World Network, a community of agents spread all around the world, with the goal, of being able to provide all logistics services globally.",
      providingLogisticsSolutions: "Providing Logistics Solutions",
      contacts: "Contacts",
      home: "Home",
      aboutUs: "About Us",
      services: "Services",
      contactUs: "Contact Us",
      getInTouch: 'Get In Touch',
      getInTouchDescription: 'Please fill out the form below to send us an email and we will get back to you as soon as possible.',
      contactInfo: 'Contact Info',
      address: 'Address',
      dominicanRepublic: 'Dominican Republic',
      panama: 'Panama',
      phone: 'Phone',
      name: 'Name',
      email: 'Email',
      message: 'Message',
      warehousingDistribuiton: 'Warehousing & Distribution',
      airImports: 'Air Imports',
      airExport: 'Air Export',
      FCLImports: 'FCL Imports',
      FCLExports: 'FCL Exports',
      IORImporterOrRecord: 'IOR (Importer or Record)',
      customBrokerage: 'Custom Brokerage',
      inland: 'Inland',
      importExportconsolidation: 'Import & Export consolidation',
      thanksForContactingUs: 'Thanks for contacting us. We will get in touch with you shortly.',
    },
    action: {
      sendMessage: 'Send Message'
    }
  }
}