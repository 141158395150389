import { useTranslation } from '../i18n/i18n';
import './assets/services.css';

export const Services = (props) => {
  const { t } = useTranslation();
  return (
    <div id="services">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{t('common.whatCanWeDoForYou')}</h2>
              <ul className="features-list-container">
                <li>{t('common.warehousingDistribuiton')}</li>
                <li>{t('common.airImports')}</li>
                <li>{t('common.airExport')}</li>
                <li>{t('common.FCLImports')}</li>
                <li>{t('common.FCLExports')}</li>
                <li>{t('common.IORImporterOrRecord')}</li>
                <li>{t('common.customBrokerage')}</li>
                <li>{t('common.inland')}</li>
                <li>{t('common.importExportconsolidation')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
